<!--  -->
<template>
	<div id="User">
		<!-- 头部 -->
		<div class="head">
			<div class="user-h">
				<div class="user-pic">
					<img v-if="!user.avatar" src="../../../assets/img/default.jpg" alt="" />
					<img v-else :src="configUrl + user.avatar" alt="" /><span class="grade-icon"><img
							v-if="!userInfo.userlevel"
							src="https://static.flsystem199.com/tc-file/44ea957aa65a5c1287c6b62cec9b4acd.png" alt="" />
						<img v-else :src="configUrl + userInfo.userlevel.img" alt="" /></span>
				</div>
				<div class="link" v-if="user.id">{{ user.nickname }}</div>
				<div class="link" @click="jumpLogin" v-else>
					{{ $t("m_mime.dlzc") }}
				</div>
				<div class="bg-account" @click="updateUser">
					<div class="money-item">
						<!-- <p class="money" @click="moneyShow()">{{ user.money | privatefg }}</p> -->
						<p class="money" @click="moneyShow()">{{ user.money | formatNum}}</p>
						<div class="money-items" v-if="show == true" >
							<p class="moneys">{{ user.money || "0" }}</p>
						</div>
						<p class="label">
							{{ $t("profile.zhhb") }}（{{ $t("common.CNY") }}）
						</p>
						<div class="line"></div>
					</div>
					<div class="money-item">
						<!-- <p class="money" @click="moneyShow1()">{{ user.tixian_money | privatefg }}</p> -->
						<p class="money" @click="moneyShow1()">{{ user.tixian_money | privateMoney}}</p>
						<div class="money-items" v-if="show1 == true" >
							<p class="moneys">{{ user.tixian_money || "0" }}</p>
						</div>
						<p class="label">
							{{ $t("profile.ktye") }}（{{ $t("common.CNY") }}）
						</p>
						<div class="line"></div>
					</div>
					<!---->
				</div>
				<div class="profile-tab">
					<div class="t-left" @click="jumpCx">
						<i class="iconfont icon-tixianchongzhi"></i>
						{{ $t("m_mime.cz") }}
					</div>
					<div class="t-left" @click="jumpWithdraw">
						<i class="iconfont icon-tixian"></i>{{ $t("m_mime.tx") }}
					</div>
					<div class="t-left" @click="jumpReport">
						<i class="iconfont icon-baobiao"></i>{{ $t("m_mime.bb") }}
					</div>
				</div>
			</div>
		</div>
		<!-- 菜单 -->
		<div class="tablist">
			<ul class="lists">
				<li class="item-d" @click="jumpPage(item)" v-for="(item, index) in menuList" :key="index">
					<img :src="item.icon" class="icon" />
					{{ item.name }}
					<i class="iconfont icon-arrow-right"></i>
				</li>
			</ul>
		</div>
		<FooterMenu />
	</div>
</template>

<script>
	import filters from "@/plugin/filters.js";
	import {
		Toast
	} from "vant";
	import FooterMenu from "@/components/mc/FooterMenu";
	import {
		mapState,
		mapMutations,
		mapActions
	} from "vuex";
	import {
		getUserLevel
	} from "@/api/user";
	export default {
		name: "",
		computed: {
			...mapState(["hasLogin", "user"])
		},
		components: {
			FooterMenu,
			filters
		},
		data() {
			return {
				show: false,
				show1: false,
				userInfo: {},
				configUrl: process.env.VUE_APP_BASE_API,
				menuList: [{
						name: this.$t("m_mime.wddj"),
						path: "/vipgrade",
						icon: "https://res-qn.ghweixiu.com/jfdev/6535f7ec78c67a2e42cf9609f9667e37.png",
					},
					{
						name: this.$t("m_mime.cpjs"),
						path: "/help",
						icon: "https://res-qn.ghweixiu.com/jfdev/93359751ae19efa5a66addfa722f0c65.png",
					},
					{
						name: this.$t("m_mime.zjpx"),
						path: "/userfunds",
						icon: "https://res-qn.ghweixiu.com/jfdev/15f96427a1e608cfaa70c04090845bca.png",
					},
					{
						name: this.$t("m_mime.tzjl"),
						path: "/orders",
						icon: "https://res-qn.ghweixiu.com/jfdev/84b5db64577626a6fd160679c3eb1e35.png",
					},
					{
						name: this.$t("m_mime.xxgg"),
						path: "/message",
						icon: "https://res-qn.ghweixiu.com/jfdev/bb385fb78140ab560665faa33ebfdff3.png",
					},
					{
						name: this.$t("m_mime.aqzx"),
						path: "/usersecurity",
						icon: "https://res-qn.ghweixiu.com/jfdev/b0db9e0606c0af78b7fa50a12ca8894e.png",
					},
					{
						name: this.$t("m_mime.flgz"),
						path: "/hallrule",
						icon: "https://res-qn.ghweixiu.com/jfdev/d5fc7b5bd163da3308179389bf089acb.png",
					},
					{
						name: this.$t("m_mime.yeb"),
						path: "/finance",
						icon: "https://res-qn.ghweixiu.com/jfdev/6b854ed698d7c4fa071c3ecfab2eb8b2.png",
					},
					{
						name: this.$t("m_mime.lxkf"),
						path: "/cusService",
						icon: "https://res-qn.ghweixiu.com/jfdev/3b8d38ede3162cf5c919a47ba03a7a7b.png",
					},
					{
						name: this.$t("m_mime.sz"),
						path: "/usersetting",
						icon: "https://res-qn.ghweixiu.com/jfdev/6bd796528149b56b1a37951587175226.png",
					},
				],
			};
		},
		watch: {
			$route: "init",
		},
		//生命周期 - 创建完成（访问当前this实例）
		created() {},
		//生命周期 - 挂载完成（访问DOM元素）
		mounted() {
			/* const toast = Toast.loading({
			    message: "登录中...",
			    forbidClick: true,
			    loadingType: "spinner",
			    duration:5000,
			  }); */
			this.init();
		},
		methods: {
			...mapActions(["GetInfo"]),
			//显示金额详细
			moneyShow() {
				if (this.show == false) {
					this.show = true
					return
				} else {
					this.show = false
					return
				}
			},
			moneyShow1() {
				if (this.show1 == false) {
					this.show1 = true
					return
				} else {
					this.show1 = false
					return
				}
			},
			init() {
				if (this.hasLogin) {
					this.getUserLevelFun();
					this.updateUser();
				}
			},
			loadingCom() {
				// 自定义加载图标
				const toast = Toast.loading({
					message: this.$t("common.loading"),
					forbidClick: true,
					loadingType: "spinner",
					duration: 60000,
				});
			},
			/* 查询我的等级 */
			getUserLevelFun() {
				getUserLevel().then((res) => {
					console.log(res);
					this.userInfo = res.data.user;
				});
			},
			updateUser() {
				this.loadingCom();
				this.GetInfo()
					.then((res) => {
						Toast.clear();
						console.log("更新成功");
					})
					.catch((err) => {
						console.log("获取用户信息失败");
					});
			},
			jumpSetting() {
				this.$router.push("/usersetting");
			},
			/* 跳转登录 */
			jumpLogin() {
				this.$router.push("/login");
			},
			/* 跳转页面 */
			jumpPage(item) {
				this.$router.push(item.path);
			},
			/* 跳转充值 */
			jumpCx() {
				this.$router.push("/recharge");
			},
			/* 跳转提现 */
			jumpWithdraw() {
				this.$router.push("/withdraw");
			},
			/* 跳转报表 */
			jumpReport() {
				this.$router.push("/reportForms");
			},
		},
	};
</script>
<style scoped lang="less">
	#User {
		width: 100%;
		height: 100%;
		position: relative;
		background-color: #222;

		.head {
			width: 100%;
			position: relative;
			background: url(../../../assets/img/mcbg.png) no-repeat;
			background-size: cover;

			.user-h {
				padding-top: 1.09rem;

				.user-pic {
					width: 1.51rem;
					height: 1.51rem;
					margin: auto;
					border-radius: 100%;
					position: relative;

					img {
						width: 100%;
						border-radius: 100%;
					}

					.grade-icon {
						position: absolute;
						bottom: -0.5rem;
						left: 50%;
						width: 1rem;
						height: 0.5rem;
						-webkit-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}
				}

				.link {
					width: 100%;
					height: 0.28rem;
					line-height: 0.28rem;
					margin-top: 0.33rem;
					font-size: 0.3rem;
					text-align: center;
					color: #fff;
				}

				.bg-account {
					margin: 0.47rem 0.2rem 0;
					height: 1.31rem;
					background-image: linear-gradient(80deg,
							#cca772 0%,
							#fbe6c7 31%,
							#eed5b0 50%,
							#e1c399 69%,
							#ecd8bb 100%);
					border-radius: 0.15rem;
					display: flex;
					justify-content: space-between;

					.money-item {
						position: relative;
						-webkit-box-flex: 1;
						-ms-flex: 1;
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.money {
							font-size: 0.36rem;
							color: #1a1a1a;
						}

						.money-items {
							background: #201810;
							border-radius: 0.0625rem;
							position: absolute;
							z-index: 9;
							top: 0.75rem;
							left: 1rem;

							.moneys {
								padding: 0.125rem;
								color: #FFFFFF;
								font-size: 0.3125rem;
							}
						}


						.label {
							font-size: 0.22rem;
							color: #38373b;
							margin-top: 0.04rem;
							text-align: center;
						}
					}
				}

				.profile-tab {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.2rem;
					box-sizing: border-box;

					.t-left {
						flex: 1;
						padding: 0 0.2rem;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 0.3rem;
						color: #fff8e9;
						position: relative;
						box-sizing: border-box;
					}
				}
			}
		}
	}

	#User .iconbaobiao {
		font-size: 0.42rem !important;
	}

	#User .iconfont {
		display: inline-block;
		color: #f7d6a5;
		margin-right: 0.2rem;
		font-size: 0.4rem;
		text-align: center;
		box-sizing: border-box;
	}

	/* 菜单部分 */
	#User .tablist {
		padding-top: 0.2rem;
		padding-bottom: 1.14rem;

		.lists {
			margin: 0 auto;
			margin-bottom: 0.2rem;
			background-color: #222222;

			.item-d {
				line-height: 0.98rem;
				font-size: 0.3rem;
				padding-left: 0.24rem;
				color: #fff8e9;
				position: relative;
				border-bottom: 0.01rem solid #404040;

				.icon {
					margin-right: 0.1rem;
					height: 0.3rem;
					width: 0.3rem;
					top: 0.02rem;
					position: relative;
				}

				.icon-arrow-right {
					position: absolute;
					right: 0rem;
				}
			}
		}
	}
</style>
